import { DateTime } from 'luxon'

export const state = {
  selectedDate: DateTime.now().setZone('America/New_York').plus({ days: 2 }).toFormat('yyyy-MM-dd'),
}

export const mutations = {
  SET_SELECTED_DATE(state, date) {
    state.selectedDate = date
  },
}

export const getters = {
  getSelectedDate(state) {
    return state.selectedDate
  },
}

export const actions = {
  setSelectedDate({ commit }, date) {
    commit('SET_SELECTED_DATE', date)
  },
}
